import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const Avatar = styled(GatsbyImage)`
  width: var(--avatar-size, 5rem);
  height: var(--avatar-size, 5rem);
  flex-shrink: 0;

  margin-right: 2rem;

  &,
  & > [data-placeholder-image] {
    border-radius: 50%;
  }
`

export default Avatar
