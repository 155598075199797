import styled from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

import { greenBox, fadedGreenBox } from "../common/ornaments"

export const GreenBox = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: absolute;
  width: var(--green-box-width);
  height: var(--green-box-height, 700px);

  padding: 80px 150px;
  z-index: 1;

  background-color: var(--faded-green);

  transform: translateY(var(--green-box-translateY, calc(-100% + 350px)));

  @media ${minD.tabletL} {
    bottom: 0;
  }

  @media ${maxD.laptopL} {
    padding: 80px;
  }

  @media ${maxD.tabletL} {
    transform: translateY(var(--green-box-translateY, calc(-100% + 190px)));

    padding: 50px 80px;
    top: 0;
  }

  @media ${maxD.mobileS} {
    align-items: flex-end;
    padding: 50px 40px;
  }
`

const BoxTitle = styled.p`
  position: relative;
  max-width: 160px;

  font-size: 0.8rem;
  margin-bottom: 2.6rem;

  &::after {
    left: -20px;
    top: -10px;

    z-index: -1;
  }
`

export const GreenBoxTitle = styled(BoxTitle)`
  ${greenBox}
`

export const FadedGreenBoxTitle = styled(BoxTitle)`
  ${fadedGreenBox}
`

export const Intro = styled.article`
  width: min(600px, 50%);

  padding-top: 90px;

  @media ${maxD.tabletL} {
    width: 100%;

    padding-top: 0;
    padding-bottom: calc(var(--section-spacing) * 0.8);
  }
`

export const IntroSpacedOnMobile = styled(Intro)`
  @media ${maxD.tabletL} {
    padding-top: 200px;
  }
`

export const HeaderInner = styled.div`
  --width: 700px;

  width: 100%;
  flex-shrink: 0;
  padding: 0 0 3.5rem 0;

  line-height: 1.4;

  @media ${minD.mobileL} {
    padding: 0 4rem 5rem 0;
  }

  @media ${minD.tablet} {
    padding: 0 4rem 7.5rem 0;
  }

  @media ${minD.tabletL} {
    width: calc(var(--width) * 0.65);
  }

  @media ${minD.laptop} {
    width: calc(var(--width) * 0.88);
  }

  @media ${minD.desktop} {
    width: var(--width);
    padding: 0 4rem 4rem 0;
  }
`
