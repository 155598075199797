import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../../assets/styles/layout"

import BlogListingHeader from "../../components/blog/posts-list/BlogListingHeader"
import Footer from "../../components/global/Footer"
import PostsList from "../../components/blog/posts-list/PostsList"
import Lead from "../../components/blog/atoms/Lead"

import {
  LineWrapperWithSpaces,
  FooterContainer,
} from "../../components/global/common/containers"

export const query = graphql`
  query ($id: String!, $language: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfBlogListing {
        header
        lead
      }
    }
    allWpPost(
      sort: { order: DESC, fields: date }
      filter: { language: { slug: { eq: $language } } }
    ) {
      nodes {
        id
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        title
        slug
        uri
        acfPost {
          leadShort
          leadLong
          author {
            ... on WpTeamMember {
              id
              title
              acfTeamMember {
                authorDescription
                authorLink {
                  target
                  title
                  url
                }
                singleUserImage {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
        categories {
          nodes {
            name
            id
            slug
          }
        }
      }
    }
  }
`

const BlogListing = ({ pageContext, data: { wpPage, allWpPost } }) => {
  const { seo, acfBlogListing } = wpPage

  const { header, lead } = acfBlogListing

  const { nodes: posts } = allWpPost

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPage} />
      )}
      <BlogListingHeader title={header} pageContext={pageContext} />
      <LineWrapperWithSpaces>
        <Lead dangerouslySetInnerHTML={{ __html: lead }} />
        <PostsList {...{ posts }} />
      </LineWrapperWithSpaces>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default BlogListing
