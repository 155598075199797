import React from "react"
import styled from "styled-components"

import { UnstyledList } from "../common/essentials"

const Category = styled.div`
  --padding-vertical: 5px;
  --padding-horizontal: 20px;

  margin-right: 10px;
`

const Categories = ({ categories }) => {
  if (!categories) return null

  return (
    <UnstyledList style={{ pointerEvents: "none" }}>
      {categories.map(({ id, slug, name }) => (
        <li key={`cat-${id}`}>
          <Category theme="green">
            {name}
          </Category>
        </li>
      ))}
    </UnstyledList>
  )
}

export default Categories
